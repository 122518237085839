import request from '@/utils/request';

const oauthProvider = process.env.VUE_APP_OAUTH_PROVIDER;

export function login(options) {
  return request({
    url: `${oauthProvider}/login`,
    method: 'post',
    data: options
  });
}

export function companyMemberLogin(options) {
  return request({
    url: 'company_members_login',
    method: 'post',
    data: options
  });
}

export function getInfo() {
  return request({
    url: '/user',
    method: 'get'
  });
}

export function logout() {
  // return request({
  //   url: 'logout',
  //   method: 'post'
  // });
}

export default {
  login,
  companyMemberLogin,
  getInfo,
  logout
};
