import axios from 'axios';
// import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import router from '@/router';
import { getToken } from '@/utils/auth';
import { setRedirectTo } from '@/utils/redirect-to';

let loadingApiCount = 0;
const toggleLoading = (loading, config) => {
  const displayLoading =
    config.displayLoading === undefined ? true : config.displayLoading;

  if (!displayLoading) {
    return;
  }

  loading ? loadingApiCount++ : loadingApiCount--;

  loadingApiCount > 0
    ? store.commit('loading-overlay/SET_LOADING', true)
    : store.commit('loading-overlay/SET_LOADING', false);
};

const responseToggleLoading = res => {
  const { config } = res;
  toggleLoading(false, config);
};

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    toggleLoading(true, config);

    config.headers['Source-OAuth-Provider'] =
      process.env.VUE_APP_OAUTH_PROVIDER;

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken();
    }
    return config;
  },
  error => {
    // do something with request error
    responseToggleLoading(error);

    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    responseToggleLoading(response);

    // if the custom code is not 20000, it is judged as an error.
    if (!res.success) {
      //laravel validator errors
      if (res.error_code == '422') {
        let errors = res.errors;
        let errKey = Object.keys(errors);

        let firstErr =
          errKey.length > 0 ? errors[errKey[0]][0] : 'Validation failed';

        alert(firstErr);
      } else {
        alert(res.message || 'Error');
        //   Message({
        //     message: res.message || 'Error',
        //     type: 'error',
        //     duration: 5 * 1000
        //   });
      }

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        // MessageBox.confirm(
        //   'You have been logged out, you can cancel to stay on this page, or log in again',
        //   'Confirm logout',
        //   {
        //     confirmButtonText: 'Re-Login',
        //     cancelButtonText: 'Cancel',
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload();
        //   });
        // });
        var r = confirm(
          'You have been logged out, you can cancel to stay on this page, or log in again'
        );
        if (r == true) {
          store.dispatch('user/resetToken').then(() => {
            location.reload();
          });
        }
      }
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res.data;
    }
  },
  error => {
    responseToggleLoading(error);

    if (error.response.status === 401) {
      if (window.location.pathname !== '/login') {
        setRedirectTo(window.location.pathname);
      }

      //line liff 特殊頁面 不要導向處理
      //頁面上自動line liff登入
      if (window.liff && window.liff.isInClient()) {
        store.dispatch('user/resetToken');
        return;
      }

      //曾經登入過
      if (getToken()) {
        var r = confirm(
          '因您可能關閉了APP或太久沒有使用，需要請您再重新登入一次唷'
        );
        if (r == true) {
          store.dispatch('user/resetToken').then(() => {
            // location.reload();
            router.replace('/login');
          });
        }
      } else {
        //無登入資訊直接去登入頁面
        router.replace('/login');
      }
      return;
    }

    if (error.response.status === 422 && error.response?.data) {
      return Promise.reject(error.response.data);
    }
    //line notify Binding
    else if (
      error.response.status === 400 &&
      error.response?.data?.error_code === '4010001'
    ) {
      if (window.location.pathname !== '/member-center/line-notify-binding') {
        setRedirectTo(window.location.pathname);
      }

      router.push({
        name: 'LineNotifyBinding'
      });
      return;
    }
    //socialite error
    else if (
      error.response.status === 400 &&
      error.response?.data?.error_code === '4010002'
    ) {
      //line liff
      if (window.liff && window.liff.isInClient()) {
        window.liff.logout();
        store.dispatch('user/resetToken');
        router.replace('/login');
        return;
      }
      return;
    } else if (
      error.response.status === 400 &&
      error.response?.data?.message !== ''
    ) {
      alert(error.response?.data?.message);

      return;
    }

    console.log('err' + error); // for debug
    alert(error.message);
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // });
    return Promise.reject(error);
  }
);

export default service;
