<template>
  <div class="search-block">
    <SearchInput
      :placeholder="placeholder"
      @on-search="$emit('on-search', $event)"
      @clear-search="$emit('clear-search')"
    />
  </div>
</template>
<script>
import SearchInput from '@/components/SearchInput';

export default {
  props: {
    placeholder: {
      required: false,
      type: String,
      default() {
        return '請輸入關鍵字';
      }
    }
  },
  components: {
    SearchInput
  }
};
</script>
