import request from '@/utils/request';

export function getWechatPayCode() {
  return request({
    url: 'member_wechat_pay_code',
    method: 'get'
  });
}

export function updateWechatPayCode({ wechat_pay_code }) {
  return request({
    url: 'member_wechat_pay_code',
    method: 'put',
    data: {
      wechat_pay_code
    }
  });
}

//取得設定值
export function getSetting(key) {
  return request({
    url: `member_settings/${key}`,
    method: 'get'
  });
}

//更新設定
export function updateSetting({ key, settings }, displayLoading = false) {
  return request({
    url: `member_settings/${key}`,
    method: 'put',
    data: {
      ...settings
    },
    displayLoading
  });
}

export function getDefaultCreateGroupSetting() {
  return request({
    url: 'member_settings/default_init_group_buying_settings',
    method: 'get'
  });
}

//開團預設設定
export function getDefaultCreateGroupRemarkSetting() {
  return request({
    url: 'member_settings/default_create_group_settings',
    method: 'get'
  });
}

//我的跟團列表
export function getMyFollowGroups(
  { search, status, page, per_page },
  displayLoading = false
) {
  return request({
    url: 'member_group_buying_orders',
    method: 'get',
    params: {
      status,
      page,
      per_page,
      search
    },
    displayLoading
  });
}

// 取得已到貨總額
export function getTotalUnpaidAmount(displayLoading = false) {
  return request({
    url: 'member_group_buying_orders/get_total_unpaid_amount',
    method: 'get',
    displayLoading
  });
}

//我的開團列表
export function getMyOwnedGroups(
  { search, status, page, per_page },
  displayLoading = false
) {
  return request({
    url: 'group_buying_orders',
    method: 'get',
    params: {
      status,
      page,
      per_page,
      search
    },
    displayLoading
  });
}

export function updateInfo({ name, avatar }, cb) {
  return request({
    url: 'member',
    method: 'put',
    data: {
      name,
      avatar
    }
  });
}

export function fetchFavoritedGroups(
  { search, per_page },
  displayLoading = false
) {
  return request({
    url: 'favorite_groups',
    method: 'get',
    params: {
      per_page,
      search
    },
    displayLoading
  });
}

export function bindLineNotify(code, displayLoading = false) {
  return request({
    url: 'line-notify',
    method: 'post',
    data: {
      code
    },
    displayLoading
  });
}

export function updateECPay(ecpay, displayLoading = false) {
  return request({
    url: 'member_ecpay',
    method: 'put',
    data: {
      ecpay
    },
    displayLoading
  });
}

export function getECPay(displayLoading = false) {
  return request({
    url: 'member_ecpay',
    method: 'get',
    displayLoading
  });
}

export default {
  getWechatPayCode,
  updateWechatPayCode,
  getSetting,
  updateSetting,
  getMyFollowGroups,
  getMyOwnedGroups,
  updateInfo,
  bindLineNotify,
  updateECPay,
  getECPay
};
