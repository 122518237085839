import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('meta', ['state'])
  },
  methods: {
    ...mapActions('meta', ['setMeta'])
  },
  watch: {
    state: {
      deep: true,
      handler() {
        this.setMeta();
      }
    }
  }
};
