import {
  getDefaultCreateGroupSetting,
  getDefaultCreateGroupRemarkSetting
} from '@/api/member';
import defaultCreateGroupSetting from '@/utils/default-create-group-setting';
import { getProduct } from '@/api/products';
import { getDefaultProduct } from '@/utils/create-group-product';
import { getGroupBuyingInfo, createGroupSettings } from '@/api/group-buying';
import { cloneDeep, get, isEmpty } from 'lodash';
import { getVendors } from '@/api/vendors';

const moment = require('moment');
const dafaultEndDate = () => {
  return moment()
    .add(1, 'days')
    .set('hour', '12')
    .set('minute', '00')
    .format('YYYY-MM-DD HH:mm');
};

const createGroup = () => {
  return {
    defaultVendor: null, //預設廠商
    cover: null, //開團封面圖
    name: null, //開團名稱
    endDate: null, //結束日期時間
    endByReachAmount: false, //以數量到達優先結團
    reachAmount: null, //到達結團數量
    fromWebsite: false, //是否從本網站帶出商品資訊
    products: [getDefaultProduct()], //開團商品
    websiteProducts: [getDefaultProduct()], //已選擇的從網站帶出的商品
    customProducts: [getDefaultProduct()], //自己填的商品
    deliveryType: 1, //寄送方式設定
    pickupLocations: [], //面交地點設定
    paymentMethods: [], //付款方式設定
    needHandlingFee: false, //需要服務費
    handlingFee: null, //服務費
    remark: '如有下單請務必加入開團主好友，以免收不到通知訊息', //團主說明
    content: null, //廠商商品介紹
    showStatistics: false, //是否将开团统计资讯显示在跟团列表内

    defaultInitGroupBuyingSettings: null, //default init group buying settings
    availablePaymentMethods: [], //可用付款方式
    vendorHint: null //廠商商品提示
  };
};

const state = createGroup();

const mutations = {
  SET_DEFAULT_INIT_GROUP_BUYING_SETTINGS: (state, settings) => {
    state.defaultInitGroupBuyingSettings = settings;
  },
  SET_COVER: (state, cover) => {
    state.cover = cover;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_END_DATE: (state, endDate) => {
    state.endDate = endDate;
  },
  SET_END_BY_REACH_AMOUNT: (state, endByReachAmount) => {
    state.endByReachAmount = endByReachAmount;
    if (endByReachAmount === false) {
      state.reachAmount = null;
    }
  },
  SET_REACH_AMOUNT: (state, reachAmount) => {
    let amount = reachAmount ? parseInt(reachAmount) : null;
    state.reachAmount = amount;
  },
  SET_FROM_WEBSITE: (state, fromWebsite) => {
    state.fromWebsite = fromWebsite;
  },
  SET_PRODUCTS: (state, products) => {
    state.products = products;
  },
  SET_WEBSITE_PRODUCTS: (state, products) => {
    state.websiteProducts = products;
  },
  SET_CUSTOM_PRODUCTS: (state, products) => {
    state.customProducts = products;
  },
  SET_DELIVERY_TYPE: (state, deliveryType) => {
    state.deliveryType = parseInt(deliveryType);
  },
  SET_PICKUP_LOCATIONS: (state, pickupLocations) => {
    state.pickupLocations = pickupLocations;
  },
  SET_PAYMENT_METHODS: (state, paymentMethods) => {
    state.paymentMethods = paymentMethods;
  },
  SET_AVAILABLE_PAYMENT_METHODS: (state, paymentMethods) => {
    state.availablePaymentMethods = paymentMethods;
  },
  SET_NEED_HANDLING_FEE: (state, needHandlingFee) => {
    state.needHandlingFee = needHandlingFee;
  },
  SET_HANDLING_FEE: (state, handlingFee) => {
    let fee = handlingFee ? parseInt(handlingFee) : null;
    state.handlingFee = fee;
  },
  SET_REMARK: (state, remark) => {
    state.remark = remark;
  },
  SET_CONTENT: (state, content) => {
    state.content = content;
  },
  SET_VENDOR_HINT: (state, hint) => {
    state.vendorHint = hint;
  },
  SET_SHOW_STATISTICS: (state, showStatistics) => {
    state.showStatistics = showStatistics;
  },
  RESET: state => {
    state = createGroup();
  }
};

const actions = {
  //開團抓取預設設定
  async setInitialOptions({ commit, dispatch, state }, { productId, groupId }) {
    await dispatch('setDefaultCreateGroupSetting');

    let vendors = await getVendors({});
    state.defaultVendor = vendors.shift();

    //set default end date
    // commit('SET_END_DATE', dafaultEndDate());
    //product mode
    if (productId !== undefined) {
      dispatch('setInitialOrderByProductMode', productId);
    }
    //group mode
    else if (groupId !== undefined) {
      dispatch('setInitialOrderByGroupMode', groupId);
    } else {
      createGroupSettings().then(data => {
        if (
          data.fromWebsite &&
          state.websiteProducts.length === 1 &&
          state.websiteProducts[0].vendor.id === null
        ) {
          state.websiteProducts[0].vendor.id = get(
            state.defaultVendor,
            'id',
            null
          );
          state.websiteProducts[0].vendor.name = get(
            state.defaultVendor,
            'name',
            null
          );

          dispatch('setFromWebSite', data.fromWebsite);
        }
      });
    }
  },
  async setDefaultCreateGroupRemark({ commit }) {
    //團主預設說明
    let data = await getDefaultCreateGroupRemarkSetting();

    if (data?.remark) {
      commit('SET_REMARK', data.remark);
    }
  },
  //開團預設設定
  async setDefaultCreateGroupSetting({ commit, dispatch }) {
    let data = await getDefaultCreateGroupSetting();
    let initialSetting = defaultCreateGroupSetting;
    let paymentMethods = [];
    if (data !== false) {
      initialSetting = data;
    }

    commit('SET_DEFAULT_INIT_GROUP_BUYING_SETTINGS', initialSetting);
    commit('SET_DELIVERY_TYPE', initialSetting.deliveryType);
    dispatch('setAvailablePaymentMethods', initialSetting.deliveryType);

    initialSetting.paymentMethods.forEach(element => {
      if (
        element.active === true &&
        state.availablePaymentMethods.includes(element.name)
      ) {
        paymentMethods.push(element.name);
      }
    });
    commit('SET_PAYMENT_METHODS', paymentMethods);
    commit('SET_PICKUP_LOCATIONS', initialSetting.shippingOptions);

    //團主預設說明
    await dispatch('setDefaultCreateGroupRemark');
  },
  //網站商品開團
  setInitialOrderByProductMode({ commit, dispatch, state }, productID) {
    getProduct(productID).then(data => {
      commit('SET_COVER', data.cover);
      commit('SET_NAME', data.name);
      commit('SET_CONTENT', data.content);
      //設定預選單位
      data.unit = data.unit_mini;
      data.unit_price = data.unit_mini_price;

      let products = cloneDeep(state.websiteProducts);
      products[products.length - 1] = getDefaultProduct(data);

      commit('SET_WEBSITE_PRODUCTS', products);
      dispatch('setFromWebSite', true);
      commit('SET_VENDOR_HINT', data.vendor_hint);
    });
  },
  //再開團
  setInitialOrderByGroupMode({ commit, dispatch }, groupID) {
    getGroupBuyingInfo(groupID).then(data => {
      commit('SET_COVER', data.cover);
      commit('SET_NAME', data.name);
      commit('SET_END_BY_REACH_AMOUNT', data.reach_amount !== null);
      commit('SET_REACH_AMOUNT', data.reach_amount);
      commit('SET_CONTENT', data.content);
      commit('SET_VENDOR_HINT', null);

      if (data.is_owner === true) {
        commit('SET_DELIVERY_TYPE', data.delivery_type);
        commit('SET_REMARK', data.remark);
      }

      let products = [];

      if (data?.products) {
        products = data.products.map(ele => getDefaultProduct(ele));
      } else {
        data.group_buying_products.forEach(ele => {
          if (!isEmpty(ele?.product?.main_product_id)) {
            ele.id = ele.product.main_product_id;
          } else {
            //自行輸入商品轉開團不帶商品id
            ele.id = null;
          }

          let findIndex = products.findIndex(p => {
            return (
              ele.product.main_product_id &&
              ele.product.main_product_id == p.product.main_product_id
            );
          });

          if (findIndex !== -1) {
            if (ele.spec_name) {
              products[findIndex].spec_names.push(ele.spec_name);
            }
          } else {
            let customProductIndex = products.findIndex(p => {
              return ele.name == p.name;
            });
            if (customProductIndex !== -1) {
              if (ele.spec_name) {
                products[customProductIndex].spec_names.push(ele.spec_name);
              }
            } else {
              products.push({
                ...ele,
                spec_names: isEmpty(ele.spec_name) ? [] : [ele.spec_name],
                spec_name: ''
              });
            }
          }
        });
        products = products.map(ele => getDefaultProduct(ele));
      }

      let fromWebsite = data.from_website == 1;

      fromWebsite
        ? commit('SET_WEBSITE_PRODUCTS', products)
        : commit('SET_CUSTOM_PRODUCTS', products);

      dispatch('setFromWebSite', fromWebsite);
    });
  },
  setFromWebSite({ commit, state }, fromWebsite) {
    //設定開關
    commit('SET_FROM_WEBSITE', fromWebsite);

    let products =
      fromWebsite === true ? state.websiteProducts : state.customProducts;

    commit('SET_PRODUCTS', products);
  },
  setAvailablePaymentMethods({ commit, rootGetters }, deliveryType) {
    let paymentMethods = [];

    switch (deliveryType) {
      //面交
      case 1:
        paymentMethods = ['Personally', 'Transfer', 'ECPay'];
        break;
      //寄送
      case 2:
        paymentMethods = ['Transfer', 'Cash_on_delivery', 'ECPay'];
        break;
    }

    if (rootGetters.isCompanyMember === false) {
      var ecpayIndex = paymentMethods.findIndex(pname => pname === 'ECPay');

      if (ecpayIndex !== -1) {
        paymentMethods.splice(ecpayIndex, 1);
      }
    }

    commit('SET_AVAILABLE_PAYMENT_METHODS', paymentMethods);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
