import { map } from 'lodash';

let defaultUnits = ['份', '個', '瓶', '件', '箱'];

export function getDefaultProduct(product = {}) {
  let defaultProduct = {
    id: null,
    vendor: {
      id: null,
      name: null
    },
    name: '',
    spec_name: '',
    spec_names: [], //可選用的規格名稱
    selected_spec_names: [], //開團選擇的規格名稱
    available_spec_names: [], //可選用的規格名稱 給tag input 讀取得格式
    tag_spec_names: [], //選擇的規格名稱 給tag input讀取格式
    fullname: '',
    unit: defaultUnits[0],
    unit_price: null,
    content: '',
    cover: '',
    status: true, //可用上架狀態,
    units: defaultUnits
  };

  return {
    ...defaultProduct,
    ...product,
    id: product?.product?.id ? product.product.id : product.id,
    selected_spec_names: product?.spec_names ? product.spec_names : [],
    tag_spec_names:
      product.spec_names !== undefined
        ? map(product.spec_names, function(spec) {
            return {
              text: spec
            };
          })
        : [],
    available_spec_names:
      product.spec_names !== undefined
        ? map(product.spec_names, function(spec) {
            return {
              text: spec
            };
          })
        : []
  };
}

export default {
  getDefaultProduct
};
