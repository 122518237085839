import request from '@/utils/request';

export function getCategories(
  { page, per_page, vendor_id },
  displayLoading = false
) {
  return request({
    url: 'categories',
    method: 'get',
    params: {
      page,
      per_page,
      vendor_id
    },
    displayLoading
  });
}

export function getProducts(
  { category, page, per_page, search, sort, dir, vendor },
  displayLoading = false
) {
  return request({
    url: 'products',
    method: 'get',
    params: {
      category,
      vendor,
      page,
      per_page,
      search,
      sort,
      dir
    },
    displayLoading
  });
}

export function getProduct(id) {
  return request({
    url: `products/${id}`,
    method: 'get'
  });
}

export function getProductsByVendor({
  vendor,
  page,
  per_page,
  search,
  sort,
  dir
}) {
  return request({
    url: `vendors/${vendor}/products`,
    method: 'get',
    params: {
      page,
      per_page,
      search,
      sort,
      dir
    }
  });
}

export default {
  getCategories,
  getProducts,
  getProduct,
  getProductsByVendor
};
