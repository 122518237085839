import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { setRedirectTo } from '@/utils/redirect-to';

const LoginPage = () => import('@/views/login');
const MemberNotification = () => import('@/views/notification');
const MemberNotificationContent = () => import('@/views/notification/content');
const Home = () => import('@/views/home');

const hideWebsiteProducts =
  process.env.VUE_APP_HIDE_WEBSITE_PRODUCTS === 'true';

/* Router Modules */
import searchRoute from './modules/search';
import memberCenterRoute from './modules/member-center';
import groupsRoute from './modules/groups';

Vue.use(VueRouter);

let routes = [
  {
    name: 'LoginPage',
    path: '/login',
    component: LoginPage,
    meta: {
      guest: true,
      pageTitle: '首次登入',
      hidePrevBtn: true,
      hideFooter: true
    }
  },
  {
    path: '/',
    name: 'Home',
    // redirect: {
    // name: hideWebsiteProducts === false ? 'SearchHome' : 'CreatedGroupList'
    // name: 'CreateGroup'
    // name: 'CreatedGroupList'
    // },
    component: Home,
    meta: {
      // pageTitle: 'Ezbuy',
      requiresAuth: false,
      hideFooter: false,
      hidePrevBtn: true
    }
  },
  {
    name: 'MemberNotification',
    path: '/notifications',
    component: MemberNotification,
    meta: {
      requiresAuth: true,
      pageTitle: '佈告欄',
      hidePrevBtn: true
    }
  },
  {
    name: 'MemberNotificationContent',
    path: '/notifications/:notification_id',
    component: MemberNotificationContent,
    meta: {
      requiresAuth: true,
      pageTitle: '佈告欄',
      prevPageName: 'MemberNotification'
    }
  },
  {
    path: '*',
    redirect: {
      name: 'Home'
    }
  }
];

routes = routes.concat(memberCenterRoute).concat(groupsRoute);

if (!hideWebsiteProducts) {
  routes = routes.concat(searchRoute);
}

const router = new VueRouter({
  mode: 'history',
  routes
});

const redirectIfNotAuth = (to, next) => {
  if (store.getters.userIsLogin) {
    next();
  } else {
    store.dispatch('user/logout');
    setRedirectTo(to.fullPath);
    next({
      path: '/login'
    });
  }
};

const redirectIfAuth = next => {
  if (store.getters.userIsLogin) {
    next({ path: '/' });
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    redirectIfNotAuth(to, next);
  }

  if (to.matched.some(record => record.meta.guest)) {
    redirectIfAuth(next);
  }

  //避免上一頁為空跳出網站
  if (from.name === null && to?.meta?.prevPageName === undefined) {
    to.meta.prevPageName = 'CreatedGroupList';
  }

  if (from.name === 'CreatedGroupList' || from.name === 'FollowedGroupList') {
    if (from.query) {
      to.meta.prevQuery = from.query;
    }
  }

  if (from.name === 'CreatedGroupManageMenu') {
    to.meta.prevQuery = {
      tab: 'valid'
    };
  }

  next();
});

export function createRouter() {
  return router;
}

export default router;
