const MemberCenter = () => import('@/views/member-center');
const MemberCenterDefaultSetting = () =>
  import('@/views/member-center/setting');
const MemberDefaultSettingCreateGroup = () =>
  import('@/views/member-center/setting/create-group-setting/index');
const MemberDefaultSettingCreateGroupRemark = () =>
  import('@/views/member-center/setting/create-group-remark/index');
const MemberDefaultSettingActiveNotification = () =>
  import('@/views/member-center/setting/active-notification/index');
const MemberDefaultSettingCanceledNotification = () =>
  import('@/views/member-center/setting/canceled-notification/index');
const MemberDefaultSettingReducedNotification = () =>
  import('@/views/member-center/setting/reduced-notification/index');
const MemberDefaultSettingTransfer = () =>
  import('@/views/member-center/setting/transfer/index');
const MemberDefaultSettingValidGroupNotification = () =>
  import('@/views/member-center/setting/valid-group-notification/index');
const MemberDefaultSettingValidNotification = () =>
  import('@/views/member-center/setting/valid-notification/index');
const FeedBack = () => import('@/views/member-center/feedback');
const OtherSetting = () => import('@/views/member-center/other-setting');
const PrivacyTermSetting = () => import('@/views/member-center/privacy');
const MemberCalendar = () => import('@/views/member-center/calendar');
const MemberCalendarDetail = () =>
  import('@/views/member-center/calendar-detail');
const CreateEvent = () => import('@/views/member-center/create-event');
const OrderList = () => import('@/views/member-center/orders');
const LineNotifyBinding = () =>
  import('@/views/member-center/line-notify-binding');

const memberCenterRoute = [
  {
    name: 'OrderList',
    path: '/member-center/orders',
    component: OrderList,
    meta: {
      requiresAuth: true,
      pageTitle: '從網站下訂查詢'
    }
  },
  {
    name: 'MemberDefaultSetting',
    path: '/member-center/default-settings',
    component: MemberCenterDefaultSetting,
    meta: {
      requiresAuth: true,
      pageTitle: '開團資訊預設'
    }
  },
  {
    name: 'MemberDefaultSettingCreateGroup',
    path: '/member-center/default-settings/create-group',
    component: MemberDefaultSettingCreateGroup,
    meta: {
      requiresAuth: true,
      pageTitle: '開團設定預設'
    }
  },
  {
    name: 'MemberDefaultSettingCreateGroupRemark',
    path: '/member-center/default-settings/create-group-remark',
    component: MemberDefaultSettingCreateGroupRemark,
    meta: {
      requiresAuth: true,
      pageTitle: '團主說明預設'
    }
  },
  {
    name: 'MemberDefaultSettingTransfer',
    path: '/member-center/default-settings/transfer',
    component: MemberDefaultSettingTransfer,
    meta: {
      requiresAuth: true,
      pageTitle: '匯款資料'
    }
  },
  {
    name: 'MemberDefaultSettingCanceledNotification',
    path: '/member-center/default-settings/canceled-notification',
    component: MemberDefaultSettingCanceledNotification,
    meta: {
      requiresAuth: true,
      pageTitle: '流團通知'
    }
  },
  {
    name: 'MemberDefaultSettingValidNotification',
    path: '/member-center/default-settings/valid-notification',
    component: MemberDefaultSettingValidNotification,
    meta: {
      requiresAuth: true,
      pageTitle: '成團通知'
    }
  },
  {
    name: 'MemberDefaultSettingActiveNotification',
    path: '/member-center/default-settings/active-notification',
    component: MemberDefaultSettingActiveNotification,
    meta: {
      requiresAuth: true,
      pageTitle: '團購中通知'
    }
  },
  {
    name: 'MemberDefaultSettingReducedNotification',
    path: '/member-center/default-settings/reduced-notification',
    component: MemberDefaultSettingReducedNotification,
    meta: {
      requiresAuth: true,
      pageTitle: '增刪單通知' //原刪單通知
    }
  },
  {
    name: 'MemberDefaultSettingValidGroupNotification',
    path: '/member-center/default-settings/valid-group-notification',
    component: MemberDefaultSettingValidGroupNotification,
    meta: {
      requiresAuth: true,
      pageTitle: '付款領貨通知'
    }
  },
  {
    name: 'Feedback',
    path: '/member-center/feedback',
    component: FeedBack,
    meta: {
      requiresAuth: true,
      pageTitle: '意見回饋'
    }
  },
  {
    name: 'OtherSetting',
    path: '/member-center/other-setting',
    component: OtherSetting,
    meta: {
      requiresAuth: true,
      pageTitle: '其他設定'
    }
  },
  {
    name: 'MemberCenter',
    path: '/member-center',
    component: MemberCenter,
    meta: {
      requiresAuth: true,
      pageTitle: '個人資訊',
      hidePrevBtn: true
    }
  },
  {
    name: 'PrivacyTermSetting',
    path: '/member-center/privacy-term-setting',
    component: PrivacyTermSetting,
    meta: {
      pageTitle: '其他設定'
    }
  },
  {
    name: 'MemberCalendar',
    path: '/member-center/calendar/:time',
    component: MemberCalendar,
    meta: {
      requiresAuth: true,
      pageTitle: '個人行事曆'
    }
  },
  {
    name: 'MemberCalendarDetail',
    path: '/member-center/calendar-detail/:time',
    component: MemberCalendarDetail,
    meta: {
      pageTitle: '個人行事曆',
      prevPageName: 'MemberCalendar'
    }
  },
  {
    name: 'CreateEvent',
    path: '/member-center/create-event',
    component: CreateEvent,
    meta: {
      pageTitle: '新增行程',
      hideFooter: true
    }
  },
  {
    name: 'LineNotifyBinding',
    path: '/member-center/line-notify-binding',
    component: LineNotifyBinding,
    meta: {
      pageTitle: 'Line Notify',
      hideFooter: true,
      requiresAuth: true
    }
  }
];

export default memberCenterRoute;
