import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import VueMoment from 'vue-moment';
import 'bootstrap';
import TimePicker from '@/components/TimePicker';
import ScrollLoader from 'vue-scroll-loader';
import LoadingOverlay from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueClipboard from 'vue-clipboard2';
import VueSocialSharing from 'vue-social-sharing';
import liff from '@line/liff';
import LIFFInspectorPlugin from '@line/liff-inspector';

liff.use(new LIFFInspectorPlugin());

var $ = global.jQuery;
global.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
)
  ? true
  : false;

Vue.prototype.$liff = liff;
Vue.config.productionTip = false;
moment.locale('zh-tw');
Vue.use(VueMoment, {
  moment
});
Vue.use(ScrollLoader);
Vue.component('DateRangePicker', DateRangePicker);
Vue.component('TimePicker', TimePicker);
Vue.component('Loading', LoadingOverlay);
Vue.use(VueClipboard);
Vue.use(VueSocialSharing, {
  networks: {
    messenger: 'fb-messenger://share/?link=@u'
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
