export default {
  data() {
    return {
      page: 1, //當前第幾頁
      dataList: [], //放置返回数据的数组
      isLoading: false, //加載中
      loadAll: false, //没有数据的变量，默认false，隐藏
      search: null, //搜尋字詞
      sortBy: null, //排序欄位,
      dir: null, //排序方向
      api: null
    };
  },
  props: {
    //每頁幾筆資料
    per_page: {
      required: false,
      type: [Number, String],
      default() {
        return 10;
      }
    }
  },
  created() {
    this.getData();
  },
  computed: {
    query() {
      return {
        page: this.page,
        per_page: this.per_page,
        search: this.search,
        ...this.sort
      };
    },
    sort() {
      return {
        sort: this.sortBy,
        dir: this.dir
      };
    }
  },
  watch: {
    search() {
      this.refreshData();
    },
    sort() {
      this.refreshData();
    }
  },
  methods: {
    reset() {
      this.page = 1;
      this.dataList = [];
      this.isLoading = false; //加載中
      this.loadAll = false; //没有数据的变量，默认false，隐藏
    },
    //重新請求資料
    refreshData() {
      this.reset();
      this.getData();
    },
    setNoMoreData() {
      this.isLoading = false; //隐藏加载中。。
      this.loadAll = true; //所有数据都加载完了
    },
    setFirstLoadData() {
      this.isLoading = true; //把"上拉加载"的变量设为true，显示
      this.loadAll = false; //把没有数据设为false，隐藏
    },
    getData() {
      //访问网络,请求数据
      // 已全部加載完畢 不執行動作
      if (this.loadAll) {
        return;
      }

      // 加載中 不執行動作
      if (this.isLoading) {
        return;
      }

      this.isLoading = true; // 設定為加载中

      //第一次加载数据
      if (this.page == 1) {
        this.setFirstLoadData();
      }

      if (typeof this.api !== 'function') {
        console.error('api設定錯誤');
        this.setNoMoreData();
        return;
      }

      this.callApi();
    },
    callApi() {
      let query = Object.entries(this.query).reduce(
        (a, [k, v]) =>
          v
            ? {
                ...a,
                [k]: v
              }
            : a,
        {}
      );

      this.api(query).then(data => {
        if (data && data.length > 0) {
          this.page++;
          //把新请求到的数据添加到dataList里
          let list = this.dataList.concat(data);

          this.dataList = list; //获取数据数组
          this.isLoading = false; //隐藏加载中。。

          if (data.length < this.per_page) {
            this.setNoMoreData();
          }
        } else {
          this.setNoMoreData();
        }
      });
    },
    loadMore() {
      this.getData();
    }
  }
};
