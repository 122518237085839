<template>
  <div class="main-content">
    <div class="provider-select">
      <div class="container">
        <ul class="provider-list">
          <li
            v-for="vendor in vendors"
            :key="vendor.id"
            :class="{ active: vendor.id === selectedVendor.id }"
            role="presentation"
          >
            <a
              :href="`#provider${vendor.id}`"
              :aria-controls="`#provider${vendor.id}`"
              role="tab"
              data-toggle="tab"
            >
              <img :src="vendor.logo" :alt="vendor.name" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="list-wrap">
      <div class="container">
        <div class="tab-content">
          <div
            v-for="vendor in vendors"
            :key="vendor.id"
            class="provider-content fade tab-pane in"
            :class="{ active: vendor.id === selectedVendor.id }"
            :id="`provider${vendor.id}`"
            role="tabpanel"
          >
            <div class="product-list-wrapper" type="list">
              <div class="product-block">
                <div class="productwrap">
                  <div class="productimg">
                    <img :src="vendor.logo" :alt="vendor.name" />
                  </div>
                  <div class="product-info-wrap">
                    <ul class="product-info">
                      <li class="company-name">{{ vendor.name }}</li>
                      <li class="description">
                        {{ vendor.description }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="product-cate-wrapper">
              <router-link
                v-for="category in categories"
                :key="category.id"
                class="cate-block"
                :to="{
                  name: 'ProductList',
                  params: {
                    category_id: category.id,
                    vendor_id: vendor.id
                  },
                  query: $route.query
                }"
              >
                <div class="cate-img">
                  <img :src="category.cover" :alt="category.name" />
                </div>
                <div class="catename">{{ category.name }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVendors } from '@/api/vendors';
import { getCategories } from '@/api/products';

export default {
  data() {
    return {
      vendors: [],
      selectedVendor: {},
      categories: []
    };
  },
  created() {
    getVendors({}).then(data => {
      this.vendors = data;

      this.setSelectedVendorByVendorId(this.$route?.query?.vendor_id);
    });

    getCategories({
      vendor_id: this.$route?.query?.vendor_id
    }).then(data => {
      this.categories = data;
    });
  },
  watch: {
    $route: {
      immediate: false,
      handler(val) {
        if (val?.query?.vendor_id) {
          this.setSelectedVendorByVendorId(val.query.vendor_id);
        }
      }
    }
  },
  methods: {
    setSelectedVendorByVendorId(vendorId) {
      if (vendorId !== undefined) {
        this.selectedVendor = this.vendors.find(
          vendor => vendor.id == this.$route.query.vendor_id
        );
      } else {
        this.selectedVendor = this.vendors.find(Boolean);
      }
    }
  }
};
</script>
