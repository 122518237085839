<template>
  <div class="main-content">
    <TopToolBar
      @on-search="updateSearch"
      @on-sort="updateSort"
      @display-change="updateListWrapperType"
    ></TopToolBar>
    <div class="list-wrap">
      <div class="container">
        <div class="product-number">{{ productNum }}項商品</div>
        <div class="product-list-wrapper" :type="listWrapperType">
          <router-link
            v-for="(product, index) in dataList"
            :key="index"
            class="product-block"
            :to="{
              name: 'ProductPage',
              params: {
                category_id: $route.params.category_id,
                vendor_id: $route.params.vendor_id,
                product_id: product.id
              },
              query: $route.query
            }"
          >
            <div class="productwrap">
              <div class="productimg">
                <img :src="product.cover" alt="product.name" />
              </div>
              <div class="product-info-wrap">
                <ul class="product-info">
                  <li class="company">{{ product.vendor.name }}</li>
                  <li class="product-name">
                    {{ product.name }}
                  </li>
                  <li class="des">{{ product.spec_description }}</li>

                  <li class="price" v-if="isFromCreateGroup">
                    <template v-if="product.price_ranges">
                      {{ product.price_ranges[0].to_quantity }}箱以上每箱$
                      {{ product.price_ranges[0].to_unit_price }}，點入看更多
                    </template>
                    <template v-else-if="product.member_level === 0">
                      每{{ product.unit }}${{ product.unit_price }} / 零售價每
                      {{ product.unit_mini }}$
                      {{ product.msrp }}
                    </template>
                    <template v-else-if="product.member_level === 1">
                      每{{ product.unit }}${{ product.unit_price }} / 團購價每
                      {{ product.unit_mini }}$
                      {{ product.unit_mini_price }}
                    </template>
                  </li>
                  <li class="price" v-else-if="product.msrp">
                    ${{ product.msrp }} / {{ product.unit_mini }}
                  </li>
                </ul>
              </div>
            </div>
          </router-link>
        </div>
        <scroll-loader
          v-if="!loadAll"
          :loader-method="getData"
          :loader-enable="!loadAll"
        ></scroll-loader>
      </div>
    </div>
  </div>
</template>
<script>
import paginateMixin from '@/mixins/paginate';
import { getProducts } from '@/api/products';
import TopToolBar from '@/components/ListTopToolBar';

export default {
  mixins: [paginateMixin],
  components: {
    TopToolBar
  },
  data() {
    return {
      api: getProducts,
      listWrapperType: 'list'
    };
  },
  computed: {
    productNum() {
      return this.dataList.length; //TODO: fix num, is total num, not page num
    },
    query() {
      return {
        page: this.page,
        per_page: this.per_page,
        search: this.search,
        vendor: this.$route.params.vendor_id,
        category: this.$route.params.category_id,
        ...this.sort
      };
    },
    isFromCreateGroup() {
      return this.$route?.query?.vendor_id !== undefined;
    }
  },
  watch: {
    '$route.params': {
      immediate: false,
      handler(val) {
        this.refreshData();
      }
    }
  },
  methods: {
    updateSearch(search) {
      this.search = search;
    },
    updateSort(options) {
      this.sortBy = options.sort;
      this.dir = options.dir;
    },
    updateListWrapperType(type) {
      this.listWrapperType = type;
    }
  }
};
</script>
