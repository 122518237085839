const state = {
  isLoading: false
};

const actions = {};

const mutations = {
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
