const CreateGroup = () => import('@/views/create-group');
const CreatedGroupList = () => import('@/views/created-group');
const CreatedGroupFollowList = () =>
  import('@/views/created-group/followed-list');
const CreatedGroupInfo = () => import('@/views/created-group/group');
const CreatedGroupFollowedDetail = () =>
  import('@/views/created-group/group-followed-detail');
const CreatedGroupNotification = () =>
  import('@/views/created-group/notification');
const SharedGroupNotification = () =>
  import('@/views/created-group/shared-notification');
const CreatedGroupManageMenu = () =>
  import('@/views/created-group/manage-menu');
const CreatedGroupManage = () => import('@/views/created-group/manage');
const CreatedGroupManageReduced = () =>
  import('@/views/created-group/manage/reduced');
const CreatedGroupManagePurchase = () =>
  import('@/views/created-group/manage/purchase');
const CreatedGroupManageCheckout = () =>
  import('@/views/created-group/manage/checkout');
const CreatedGroupManageAddition = () =>
  import('@/views/created-group/manage/addition');
const CreatedGroupManageChangePrice = () =>
  import('@/views/created-group/manage/change-price');
const CreatedGroupCancel = () => import('@/views/created-group/cancel');
const FollowedGroupList = () => import('@/views/followed-group');
const FollowGroup = () => import('@/views/follow-group');
const FollowedList = () => import('@/views/followed-group/followed-list');
const GroupInfo = () => import('@/views/followed-group/group-info');
const FollowedOrderDetail = () => import('@/views/followed-group/order-detail');
const PaymentMemberGroupBuyingOrder = () =>
  import('@/views/payment/member-group-buying-order');

const groupsRoute = [
  {
    name: 'CreateGroup',
    path: '/create-group',
    component: CreateGroup,
    meta: {
      requiresAuth: true,
      pageTitle: '新增開團',
      hideFooter: true
    }
  },
  {
    name: 'CreatedGroupInfo',
    path: '/created-group/:group_id',
    component: CreatedGroupInfo,
    meta: {
      requiresAuth: true,
      pageTitle: '開團資訊',
      hideFooter: true,
      prevPageName: 'CreatedGroupList'
    }
  },
  {
    name: 'CreatedGroupFollowList',
    path: '/groups/:group_id/followed-list',
    component: CreatedGroupFollowList,
    meta: {
      requiresAuth: true,
      pageTitle: '團友跟團',
      hideFooter: true
    }
  },
  {
    name: 'CreatedGroupFollowedDetail',
    path: '/groups/:group_id/followed-details/:order_id',
    component: CreatedGroupFollowedDetail,
    meta: {
      requiresAuth: true,
      pageTitle: '團友跟團',
      hideFooter: true
    }
  },
  {
    name: 'CreatedGroupNotification',
    path: '/groups/:group_id/notification/:type',
    component: CreatedGroupNotification,
    meta: {
      requiresAuth: true,
      pageTitle: '通知訊息',
      hideFooter: true
    }
  },
  {
    name: 'SharedGroupNotification',
    path: '/groups/:group_id/shared-notification/:message',
    component: SharedGroupNotification,
    meta: {
      pageTitle: '通知訊息',
      hideFooter: true,
      hidePrevBtn: true
    }
  },
  {
    name: 'CreatedGroupManageMenu',
    path: '/groups/:group_id/manage-menu',
    component: CreatedGroupManageMenu,
    meta: {
      requiresAuth: true,
      pageTitle: '結團後作業'
    }
  },
  {
    name: 'CreatedGroupManage',
    path: '/groups/:group_id/manage',
    component: CreatedGroupManage,
    meta: {
      requiresAuth: true,
      pageTitle: '成團後作業',
      hideFooter: true
    }
  },
  {
    name: 'CreatedGroupCancel',
    path: '/groups/:group_id/cancel',
    component: CreatedGroupCancel,
    meta: {
      requiresAuth: true,
      pageTitle: '流團通知',
      hideFooter: true
    }
  },
  {
    name: 'CreatedGroupManageReduced',
    path: '/groups/:group_id/manage/reduced',
    component: CreatedGroupManageReduced,
    meta: {
      requiresAuth: true,
      pageTitle: '增減訂單作業', //原刪單作業
      hideFooter: true
    }
  },
  {
    name: 'CreatedGroupManagePurchase',
    path: '/groups/:group_id/manage/purchase',
    component: CreatedGroupManagePurchase,
    meta: {
      requiresAuth: true,
      pageTitle: '採購作業',
      hideFooter: true
    },
    props: route => ({
      fromWebsite: route.query.from_website == 1 ? true : false,
      orderWebsite: route.query.order_from == 1 ? true : false
    })
  },
  {
    name: 'CreatedGroupManageCheckout',
    path: '/groups/:group_id/manage/checkout',
    component: CreatedGroupManageCheckout,
    meta: {
      requiresAuth: true,
      pageTitle: '採購作業',
      hideFooter: true
    }
  },
  {
    name: 'CreatedGroupManageAddition',
    path: '/groups/:group_id/manage/addition',
    component: CreatedGroupManageAddition,
    meta: {
      requiresAuth: true,
      pageTitle: '採購作業',
      hideFooter: true
    },
    props: route => ({
      fromWebsite: route.query.from_website == 1 ? true : false,
      orderWebsite: route.query.order_from == 1 ? true : false
    })
  },
  {
    name: 'CreatedGroupManageChangePrice',
    path: '/groups/:group_id/manage/change-price',
    component: CreatedGroupManageChangePrice,
    meta: {
      requiresAuth: true,
      pageTitle: '改價作業',
      hideFooter: true
    }
  },
  {
    name: 'CreatedGroupList',
    path: '/created-group',
    component: CreatedGroupList,
    meta: {
      requiresAuth: true,
      pageTitle: '我的開團',
      hidePrevBtn: true
    }
  },
  {
    name: 'FollowedGroupList',
    path: '/followed-group',
    component: FollowedGroupList,
    meta: {
      requiresAuth: true,
      pageTitle: '我的跟團',
      hidePrevBtn: true
    }
  },
  {
    name: 'GroupInfo',
    path: '/group-info/:group_id',
    component: GroupInfo,
    meta: {
      pageTitle: '開團資訊',
      hideFooter: true,
      prevPageName: 'FollowedGroupList'
    }
  },
  {
    name: 'FollowGroup',
    path: '/follow-group/:group_id',
    component: FollowGroup,
    meta: {
      // requiresAuth: true,
      pageTitle: '團購預訂',
      hideFooter: true
    }
  },
  {
    //for line liff
    name: 'LineLIFFFollowGroup',
    path: '/follow-group',
    component: FollowGroup,
    meta: {
      pageTitle: '團購預訂',
      hideFooter: true
    }
  },
  {
    name: 'FollowedList',
    path: '/followed-group/:group_id/list',
    component: FollowedList,
    meta: {
      requiresAuth: true,
      pageTitle: '團友跟團',
      hideFooter: true
    }
  },
  {
    //for line liff 團友付款app
    name: 'LineLIFFFollowedList',
    path: '/followed-group-list',
    component: FollowedList,
    meta: {
      requiresAuth: false,
      pageTitle: '團友跟團',
      hideFooter: true
    }
  },
  {
    name: 'FollowedOrderDetail',
    path: '/followed-group/:group_id/detail/:order_id',
    component: FollowedOrderDetail,
    meta: {
      requiresAuth: true,
      pageTitle: '團友跟團',
      hideFooter: true,
      hidePrevBtn: true
    }
  },
  {
    name: 'PaymentMemberGroupBuyingOrder',
    path: '/payment/member-group-buying-order',
    component: PaymentMemberGroupBuyingOrder,
    meta: {
      requiresAuth: false,
      pageTitle: '綠界線上付款',
      hideFooter: true,
      hidePrevBtn: true
    }
  }
];

export default groupsRoute;
