var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('TopToolBar',{on:{"on-search":_vm.updateSearch,"on-sort":_vm.updateSort,"display-change":_vm.updateListWrapperType}}),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"product-number"},[_vm._v(_vm._s(_vm.productNum)+"項商品")]),_c('div',{staticClass:"product-list-wrapper",attrs:{"type":_vm.listWrapperType}},_vm._l((_vm.dataList),function(product,index){return _c('router-link',{key:index,staticClass:"product-block",attrs:{"to":{
            name: 'ProductPage',
            params: {
              category_id: _vm.$route.params.category_id,
              vendor_id: _vm.$route.params.vendor_id,
              product_id: product.id
            },
            query: _vm.$route.query
          }}},[_c('div',{staticClass:"productwrap"},[_c('div',{staticClass:"productimg"},[_c('img',{attrs:{"src":product.cover,"alt":"product.name"}})]),_c('div',{staticClass:"product-info-wrap"},[_c('ul',{staticClass:"product-info"},[_c('li',{staticClass:"company"},[_vm._v(_vm._s(product.vendor.name))]),_c('li',{staticClass:"product-name"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('li',{staticClass:"des"},[_vm._v(_vm._s(product.spec_description))]),(_vm.isFromCreateGroup)?_c('li',{staticClass:"price"},[(product.price_ranges)?[_vm._v(" "+_vm._s(product.price_ranges[0].to_quantity)+"箱以上每箱$ "+_vm._s(product.price_ranges[0].to_unit_price)+"，點入看更多 ")]:(product.member_level === 0)?[_vm._v(" 每"+_vm._s(product.unit)+"$"+_vm._s(product.unit_price)+" / 零售價每 "+_vm._s(product.unit_mini)+"$ "+_vm._s(product.msrp)+" ")]:(product.member_level === 1)?[_vm._v(" 每"+_vm._s(product.unit)+"$"+_vm._s(product.unit_price)+" / 團購價每 "+_vm._s(product.unit_mini)+"$ "+_vm._s(product.unit_mini_price)+" ")]:_vm._e()],2):(product.msrp)?_c('li',{staticClass:"price"},[_vm._v(" $"+_vm._s(product.msrp)+" / "+_vm._s(product.unit_mini)+" ")]):_vm._e()])])])])}),1),(!_vm.loadAll)?_c('scroll-loader',{attrs:{"loader-method":_vm.getData,"loader-enable":!_vm.loadAll}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }