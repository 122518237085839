<template>
  <div class="list-console">
    <div class="console-wrap">
      <SearchBlock @on-search="updateSearch"></SearchBlock>
      <div class="list-tool">
        <div
          class="fc-icon sort"
          @click="showSortByMenu = !showSortByMenu"
        ></div>
        <!--.style-block 方格/ .style-list 清單-->
        <div
          :class="['fc-icon', 'switcher', displayIconClass]"
          @click="updateDisplayType"
        ></div>
      </div>
    </div>
    <div class="list-sort-menu" :class="{ active: showSortByMenu }">
      <div
        class="sort-type"
        v-for="option in sortByOptions"
        :key="`${option.sort}-${option.dir}`"
        @click="sortList"
        :data-sort="option.sort"
        :data-dir="option.dir"
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
import SearchBlock from '@/components/ListSearchBlock';

export default {
  props: {
    sortByOptions: {
      required: false,
      type: Array,
      default() {
        return [
          {
            sort: 'unit_price',
            dir: 'desc',
            text: '價格降序'
          },
          {
            sort: 'unit_price',
            dir: 'asc',
            text: '價格升序'
          }
        ];
      }
    }
  },
  components: {
    SearchBlock
  },
  data() {
    return {
      showSortByMenu: false,
      displayList: true
    };
  },
  computed: {
    displayIconClass() {
      return this.displayList ? 'style-list' : 'style-block';
    },
    displayType() {
      return this.displayList ? 'list' : 'block';
    }
  },
  methods: {
    updateSearch(search) {
      this.$emit('on-search', search);
    },
    sortList(e) {
      let sortBy = e.currentTarget.dataset.sort;
      let dir = e.currentTarget.dataset.dir;
      this.$emit('on-sort', {
        sort: sortBy,
        dir: dir
      });
      this.showSortByMenu = false;
    },
    updateDisplayType(e) {
      this.displayList = !this.displayList;
      this.$emit('display-change', this.displayType);
    }
  },
  mounted() {}
};
</script>
