<template>
  <div id="app">
    <loading :active.sync="apiLoading" />
    <header v-if="!hideHeader">
      <div class="prev" :style="prevBtnStyle" @click="goPrevPage"></div>
      <h1 class="page-title">{{ pageTitle }}</h1>
    </header>
    <router-view></router-view>
    <footer v-if="!hideFooter">
      <FooterMenu></FooterMenu>
    </footer>
  </div>
</template>

<script>
import FooterMenu from '@/components/FooterMenu';
import MetaMixin from '@/mixins/meta-mixin';
import { mapGetters, mapActions } from 'vuex';
import liff from '@line/liff';

export default {
  mixins: [MetaMixin],
  components: {
    FooterMenu
  },
  beforeCreate() {
    if (process.env.VUE_APP_OAUTH_PROVIDER === 'line') {
      this.$liff.init({
        liffId: process.env.VUE_APP_LIFF_ID
      });
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['apiLoading', 'userIsLogin']),
    pageTitle() {
      return this.$route.meta.pageTitle;
    },
    hidePrevBtn() {
      return this.$route?.meta?.hidePrevBtn === true || this.share === true;
    },
    hideHeader() {
      return this.$route.meta.hideHeader === true;
    },
    hideFooter() {
      return this.$route.meta.hideFooter === true;
    },
    prevPageName() {
      return this.$route.meta.prevPageName;
    },
    prevBtnStyle() {
      return {
        display: this.hidePrevBtn ? 'none' : 'block'
      };
    },
    share() {
      return this.$route?.query?.share == 1;
    }
  },
  methods: {
    ...mapActions('user', ['getInfo']),
    goPrevPage() {
      if (this.hidePrevBtn) {
        return;
      }

      if (this.prevPageName !== undefined) {
        if (this.$route?.meta?.prevQuery) {
          this.$router.push({
            name: this.prevPageName,
            query: this.$route.meta.prevQuery
          });
        } else {
          this.$router.push({ name: this.prevPageName });
        }

        return;
      }

      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    }
  },
  created() {
    if (this.userIsLogin === true) {
      this.getInfo();
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
