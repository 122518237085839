<template>
  <div class="main-content top">
    <div class="product-img-top">
      <div class="prev" @click="goPrevPage"></div>
      <img :src="cover" alt="name" v-show="cover" />
    </div>
    <div class="product-info-wrapper">
      <div class="content-block">
        <div class="container">
          <div class="product-data">
            <div class="company">{{ vendorName }}</div>
            <div class="product-name">
              {{ name }}
            </div>
            <div class="product-name" v-if="showGroupPrice && unitMiniPrice">
              <template v-if="priceRanges">
                <span
                  class="price"
                  v-for="range in priceRanges"
                  :key="`from-${range.from_quantity}-to-${range.to_quantity}`"
                >
                  {{ range.to_quantity }}{{ unit }}以上每{{ unit }}$
                  {{ range.to_unit_price }}
                  <br />
                </span>
              </template>
              <template v-else>
                <span class="price">每{{ unit }}${{ unitPrice }}</span>
                <span class="price" v-if="memberLevel === 0">
                  &nbsp; / 零售價每{{ unitMini }}${{ msrp }}
                </span>
                <span class="price" v-else-if="memberLevel === 1">
                  &nbsp; / 團購價每{{ unitMini }}${{ unitMiniPrice }}
                </span>
              </template>
            </div>
            <div class="product-name" v-else-if="msrp">
              <span class="price">建議售價${{ msrp }} / {{ unitMini }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-block">
        <h2 class="md-title">商品規格</h2>
        <div class="container">
          <div class="product-text">
            <p>
              {{ specDescription }}
            </p>
          </div>
        </div>
      </div>
      <div class="content-block">
        <h2 class="md-title">商品說明</h2>
        <div class="container">
          <div class="product-text" v-html="content"></div>
        </div>
      </div>
      <div class="content-block">
        <h2 class="md-title">備註</h2>
        <div class="container">
          <div class="product-text" v-html="remark"></div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-block">
      <router-link
        class="btn-cta"
        :to="{
          name: 'CreateGroup',
          query: {
            product_id: productId
          }
        }"
      >
        我要開團
      </router-link>
    </div>
  </div>
</template>
<script>
import { getProduct } from '@/api/products';
import { get } from 'lodash';

export default {
  data() {
    return {
      product: {}
    };
  },
  computed: {
    productId() {
      return get(this.product, 'id');
    },
    name() {
      return get(this.product, 'name');
    },
    vendorName() {
      return get(this.product, 'vendor.name');
    },
    cover() {
      return get(this.product, 'cover');
    },
    unitPrice() {
      return get(this.product, 'unit_price');
    },
    unit() {
      return get(this.product, 'unit');
    },
    unitMiniPrice() {
      return get(this.product, 'unit_mini_price');
    },
    unitMini() {
      return get(this.product, 'unit_mini');
    },
    specDescription() {
      return get(this.product, 'spec_description');
    },
    content() {
      return get(this.product, 'content');
    },
    remark() {
      return get(this.product, 'remark');
    },
    //產品建議售價
    msrp() {
      return get(this.product, 'msrp');
    },
    memberLevel() {
      return get(this.product, 'member_level', 0);
    },
    //小程序價格
    priceRanges() {
      return get(this.product, 'price_ranges');
    },
    //是否顯示團購價
    showGroupPrice() {
      return (
        this.$route.query?.group == 1 ||
        this.$route.query?.vendor_id !== undefined
      );
    }
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(val) {
        getProduct(val.product_id).then(data => {
          this.product = data;
        });
      }
    }
  },
  methods: {
    goPrevPage() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    }
  }
};
</script>
