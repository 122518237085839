var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"provider-select"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"provider-list"},_vm._l((_vm.vendors),function(vendor){return _c('li',{key:vendor.id,class:{ active: vendor.id === _vm.selectedVendor.id },attrs:{"role":"presentation"}},[_c('a',{attrs:{"href":("#provider" + (vendor.id)),"aria-controls":("#provider" + (vendor.id)),"role":"tab","data-toggle":"tab"}},[_c('img',{attrs:{"src":vendor.logo,"alt":vendor.name}})])])}),0)])]),_c('div',{staticClass:"list-wrap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"tab-content"},_vm._l((_vm.vendors),function(vendor){return _c('div',{key:vendor.id,staticClass:"provider-content fade tab-pane in",class:{ active: vendor.id === _vm.selectedVendor.id },attrs:{"id":("provider" + (vendor.id)),"role":"tabpanel"}},[_c('div',{staticClass:"product-list-wrapper",attrs:{"type":"list"}},[_c('div',{staticClass:"product-block"},[_c('div',{staticClass:"productwrap"},[_c('div',{staticClass:"productimg"},[_c('img',{attrs:{"src":vendor.logo,"alt":vendor.name}})]),_c('div',{staticClass:"product-info-wrap"},[_c('ul',{staticClass:"product-info"},[_c('li',{staticClass:"company-name"},[_vm._v(_vm._s(vendor.name))]),_c('li',{staticClass:"description"},[_vm._v(" "+_vm._s(vendor.description)+" ")])])])])])]),_c('div',{staticClass:"product-cate-wrapper"},_vm._l((_vm.categories),function(category){return _c('router-link',{key:category.id,staticClass:"cate-block",attrs:{"to":{
                name: 'ProductList',
                params: {
                  category_id: category.id,
                  vendor_id: vendor.id
                },
                query: _vm.$route.query
              }}},[_c('div',{staticClass:"cate-img"},[_c('img',{attrs:{"src":category.cover,"alt":category.name}})]),_c('div',{staticClass:"catename"},[_vm._v(_vm._s(category.name))])])}),1)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }