<template>
  <div class="main-content white">
    <div class="container">
      <div class="product-cate-wrapper">
        <router-link
          v-for="(category, index) in dataList"
          :key="index"
          class="cate-block"
          :to="{
            name: 'ProductList',
            params: {
              category_id: category.id,
              vendor_id: $route.params.vendor_id
            }
          }"
        >
          <div class="cate-img">
            <img :src="category.cover" :alt="category.name" />
          </div>
          <div class="catename">{{ category.name }}</div>
        </router-link>
      </div>
      <scroll-loader
        v-if="!loadAll"
        :loader-method="getData"
        :loader-enable="!loadAll"
      ></scroll-loader>
    </div>
  </div>
</template>

<script>
import paginateMixin from '@/mixins/paginate';
import { getCategories } from '@/api/products';

export default {
  mixins: [paginateMixin],
  data() {
    return {
      api: getCategories
    };
  },
  computed: {
    query() {
      return {
        page: this.page,
        per_page: this.per_page,
        vendor_id: this.$route.params.vendor_id
      };
    }
  },
  watch: {
    '$route.params.vendor_id': {
      immediate: false,
      handler(val) {
        this.refreshData();
      }
    }
  }
};
</script>
