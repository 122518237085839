<template>
  <div class="search-wrap">
    <input
      type="text"
      class="searching"
      :placeholder="placeholder"
      @focus="isFocus = true"
      @blur="isFocus = false"
      v-model="search"
    />
    <div
      class="del"
      :class="{ active: isFocus || search !== null }"
      @click="clearSearch"
    ></div>
  </div>
</template>
<script>
import { debounce } from 'lodash';

export default {
  props: {
    placeholder: {
      required: false,
      type: String,
      default() {
        return '請輸入關鍵字';
      }
    }
  },
  data() {
    return {
      isFocus: false,
      search: null
    };
  },
  methods: {
    clearSearch() {
      this.search = null;
      this.$emit('clear-search');
    }
  },
  watch: {
    search: debounce(function(newVla) {
      this.$emit('on-search', newVla);
    }, 500)
  }
};
</script>
