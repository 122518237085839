import paymentMethods from '@/utils/payment-methods';
import deliveryTypes from '@/utils/delivery-types';

const defaultDeliveryType = deliveryTypes[0];

const defaultSetting = {
  deliveryType: defaultDeliveryType.value,
  shippingOptions: [
    {
      time_from: '',
      time_to: '',
      location: ''
    }
  ],
  paymentMethods: paymentMethods
};

export default defaultSetting;
