const paymentMethods = [
  {
    name: 'Personally',
    description: '面交付款',
    active: false
  },
  {
    name: 'Transfer',
    description: '匯款',
    active: false
  },
  {
    name: 'Cash_on_delivery',
    description: '貨到付款',
    active: false
  },
  {
    name: 'ECPay',
    description: '綠界付款',
    active: false
  }
];

export default paymentMethods;
