import request from '@/utils/request';

//開團
export function initiateOrder({
  products,
  cover,
  name,
  end_date,
  end_by_reach_amount,
  reach_amount,
  delivery_type,
  pickup_locations,
  payment_methods,
  need_handling_fee,
  handling_fee,
  remark,
  content,
  show_statistics,
  from_website,
  banners
}) {
  return request({
    url: 'group_buying_orders',
    method: 'post',
    data: {
      products,
      cover,
      name,
      end_date,
      end_by_reach_amount,
      reach_amount,
      delivery_type,
      pickup_locations,
      payment_methods,
      need_handling_fee,
      handling_fee,
      remark,
      content,
      show_statistics,
      from_website,
      banners
    }
  });
}

//開團資訊
export function getGroupBuyingInfo(id, auth = true) {
  let url = auth
    ? `group_buying_orders/${id}`
    : `group_buying_orders/${id}/no_auth`;

  return request({
    url,
    method: 'get'
  });
}

//跟團
export function followGroupBuying({
  group_buying_order_id,
  products,
  payment_method_name,
  update_default_setting,
  member_contact,
  pickup_location,
  delivery_type
}) {
  return request({
    url: 'member_group_buying_orders',
    method: 'post',
    data: {
      group_buying_order_id,
      products,
      payment_method_name,
      update_default_setting,
      member_contact,
      delivery_type,
      pickup_location
    }
  });
}

// 編輯跟團
export function updateMemberGroupBuyingOrder({
  member_group_buying_order_id,
  group_buying_order_id,
  products,
  payment_method_name,
  update_default_setting,
  member_contact,
  pickup_location,
  delivery_type
}) {
  return request({
    url: `member_group_buying_orders/${member_group_buying_order_id}`,
    method: 'put',
    data: {
      group_buying_order_id,
      products,
      payment_method_name,
      update_default_setting,
      member_contact,
      delivery_type,
      pickup_location
    }
  });
}

//強制結團
export function forceValid(group_buying_order_id) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/valid_notification`,
    method: 'put'
  });
}

//開團者跟團列表
export function getGroupFollowList(group_buying_order_id) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/members`,
    method: 'get'
  });
}

//銀貨兩訖
export function forceComplete(group_buying_order_id) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/completed`,
    method: 'put'
  });
}

//跟團者跟團內列表
export function getMyFollowedGroupList(group_buying_order_id) {
  return request({
    url: `member_group_buying_orders/${group_buying_order_id}/list`,
    method: 'get'
  });
}

//跟團者跟團內詳細
export function getFollowedGroupDetail(member_group_buying_order_id) {
  return request({
    url: `member_group_buying_orders/${member_group_buying_order_id}`,
    method: 'get'
  });
}

//更新會員跟團內容
export function updateMemberGroupBuyingOrderStatus({
  member_group_buying_order_id,
  payment_status,
  shipping_status,
  bankno
}) {
  return request({
    url: `member_group_buying_orders/${member_group_buying_order_id}/status`,
    method: 'put',
    data: {
      payment_status,
      shipping_status,
      bankno
    }
  });
}

//流團通知的跟團列表
export function getFailedFollowList(group_buying_order_id, cb) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/failed_list`,
    method: 'get'
  });
}

//流團
export function cancel({ group_buying_order_id }) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/cancel`,
    method: 'put'
  });
}

export function getGroupReducedList({ group_buying_order_id }, cb) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/reduced_list`,
    method: 'get'
  });
}

//刪單
export function groupReduceQuantity({
  member_group_buying_order,
  group_buying_order_details
}) {
  return request({
    url: `member_group_buying_orders/${member_group_buying_order}/details`,
    method: 'put',
    data: {
      group_buying_order_details
    }
  });
}

export function memberGroupCheckout(member_group_buying_order_no) {
  return request({
    url: `member_group_buying_orders/${member_group_buying_order_no}/checkout`,
    method: 'post'
  });
}

//改價作業列表
export function getChangePriceGroupBuyingProductList({
  group_buying_order_id,
  order_from = 2
}) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/group_buying_product_list`,
    method: 'get',
    params: {
      order_from
    }
  });
}

//改價作業
export function updateGroupBuyingProducts({
  group_buying_order_id,
  group_buying_products
}) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/group_buying_products`,
    method: 'put',
    data: {
      group_buying_products
    }
  });
}

//結團管理需要的基本欄位
export function getGroupBuyingManageInfo(id) {
  return request({
    url: `group_buying_orders/${id}/manage_list_info`,
    method: 'get'
  });
}

export function addGroupBuyingProductsAdditionalQty({
  group_buying_order_id,
  group_buying_products
}) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}`,
    method: 'put',
    data: {
      group_buying_products
    }
  });
}

export function finishedStep2({
  group_buying_order_id,
  finished_step2 = true
}) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}`,
    method: 'put',
    data: {
      finished_step2
    }
  });
}

export function getProductUnitPrice({
  group_buying_order_id,
  group_buying_product_id,
  actual_quantity,
  order_from
}) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/group_buying_products/${group_buying_product_id}/range_price`,
    method: 'get',
    params: {
      actual_quantity,
      order_from
    }
  });
}

export function setValidGroup({ group_buying_order_id }) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/valid`,
    method: 'put'
  });
}

//取得匯出跟團明細的signed url
export function getExportGroupListSignedUrl({ group_buying_order_id }) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/members_export_signed_url`,
    method: 'get'
  });
}

//採購作業 group-step02
export function getPurchaseGroupBuyingProductList({
  group_buying_order_id,
  order_from = 2
}) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/purchase_group_buying_product_list`,
    method: 'get',
    params: {
      order_from
    }
  });
}

//取得匯出採購單的signed url
export function getExportGroupProductsListSignedUrl({ group_buying_order_id }) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/initiator_export_signed_url`,
    method: 'get'
  });
}

//加入未跟團列表
export function toggleFavorite(group_buying_order_id) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/favorite`,
    method: 'post'
  });
}

//開團設定
export function createGroupSettings() {
  return request({
    url: 'create_group_settings',
    method: 'get'
  });
}

//發送通知
export function notify(
  group_buying_order_id,
  message,
  type = 'line_notify',
  with_payment_link = false,
  with_arrival = false
) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/notify`,
    method: 'post',
    data: {
      message,
      type,
      with_payment_link,
      with_arrival
    }
  });
}

export default {
  initiateOrder,
  getGroupBuyingInfo,
  followGroupBuying,
  updateMemberGroupBuyingOrder,
  forceValid,
  getGroupFollowList,
  forceComplete,
  getMyFollowedGroupList,
  getFollowedGroupDetail,
  updateMemberGroupBuyingOrderStatus,
  getFailedFollowList,
  cancel,
  getGroupReducedList,
  groupReduceQuantity,
  getChangePriceGroupBuyingProductList,
  updateGroupBuyingProducts,
  getGroupBuyingManageInfo,
  addGroupBuyingProductsAdditionalQty,
  finishedStep2,
  getProductUnitPrice,
  setValidGroup,
  getExportGroupListSignedUrl,
  getPurchaseGroupBuyingProductList,
  getExportGroupProductsListSignedUrl,
  toggleFavorite,
  createGroupSettings,
  memberGroupCheckout,
  notify
};
