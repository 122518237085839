<template>
  <div class="main-content top">
    <div class="list-wrap">
      <div class="container">
        <div class="product-list-wrapper" type="list">
          <router-link
            v-for="(vendor, index) in dataList"
            :to="{
              name: 'ProductCategoryList',
              params: { vendor_id: vendor.id }
            }"
            :key="index"
            class="product-block"
          >
            <div class="productwrap">
              <div class="productimg">
                <img :src="vendor.logo" :alt="vendor.name" />
              </div>
              <div class="product-info-wrap">
                <ul class="product-info">
                  <li class="company-name">{{ vendor.name }}</li>
                  <li class="description">{{ vendor.brief }}</li>
                </ul>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <scroll-loader
        v-if="!loadAll"
        :loader-method="getData"
        :loader-enable="!loadAll"
      ></scroll-loader>
    </div>
  </div>
</template>
<script>
import { getVendors } from '@/api/vendors';
import paginateMixin from '@/mixins/paginate';
export default {
  mixins: [paginateMixin],
  data() {
    return {
      api: getVendors
    };
  }
};
</script>
