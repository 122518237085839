import router from '@/router';
import { generateUrlByRoute } from '@/utils/url';

const subTitle = '團購APP';
const siteName = '易集買.';
const title = `${siteName} | ${subTitle}`;
const description = '團購APP';
const image = '';
const url = `"${process.env.VUE_APP_URL}"`;

const state = {
  siteName,
  title,
  subTitle,
  description,
  image,
  url
};

const mutations = {
  SET_SUBTITLE(state, metaSubTitle) {
    state.subTitle = metaSubTitle;
    state.title = metaSubTitle ? `${metaSubTitle} | ${state.siteName}` : title;
  },
  SET_META_IMAGE(state, metaImage) {
    state.image = metaImage ? metaImage : image;
  },
  SET_META_DESCRIPTION(state, metaDescription) {
    state.description = metaDescription ? metaDescription : description;
  },
  SET_META_URL(state, metaUrl) {
    state.url = metaUrl ? metaUrl : url;
  }
};

const getters = {
  state(state) {
    return state;
  },
  siteTitle(state) {
    return state.title;
  },
  siteDescription(state) {
    return state.description;
  },
  metaImage(state) {
    return state.image;
  },
  metaUrl(state) {
    return state.url;
  }
};

const actions = {
  setMeta({ getters }) {
    $('head title').html(getters.siteTitle);
    $('meta[name="keywords"]').attr('content', getters.siteDescription);
    $('meta[name="description"]').attr('content', getters.siteDescription);
    $('meta[property="og:description"]').attr(
      'content',
      getters.siteDescription
    );
    $('meta[property="og:title"]').attr('content', getters.siteTitle);
    $('meta[property="og:image"]').attr('content', getters.metaImage);
    $('meta[property="og:url"]').attr('content', getters.metaUrl);
  },
  setGroupMeta({ commit }, group) {
    let url = generateUrlByRoute(
      router.resolve({
        name: 'GroupInfo',
        params: {
          group_id: group.id
        }
      })
    );
    commit('SET_SUBTITLE', group.name);
    commit('SET_META_DESCRIPTION', group.content);
    commit('SET_META_IMAGE', group.cover);
    commit('SET_META_URL', url);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
