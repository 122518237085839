<template>
  <div class="footer-menu">
    <!-- <router-link
      v-show="!hideWebsiteProducts"
      class="find"
      :to="{ name: 'VendorList' }"
      active-class="active"
    >
      尋找商品
    </router-link> -->
    <!-- <router-link
      v-show="!hideWebsiteProducts"
      class="find"
      :to="{ name: 'SearchHome' }"
      active-class="active"
    >
      尋找商品
    </router-link> -->
    <router-link
      class="follow"
      :to="{ name: 'FollowedGroupList' }"
      active-class="active"
    >
      我的跟團
    </router-link>
    <router-link
      class="mygroup"
      :to="{ name: 'CreatedGroupList' }"
      active-class="active"
    >
      我的開團
    </router-link>
    <router-link
      class="profile"
      :to="{ name: 'MemberCenter' }"
      active-class="active"
    >
      個人資訊
    </router-link>
    <router-link
      class="notify"
      :to="{ name: 'MemberNotification' }"
      active-class="active"
    >
      佈告欄
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hideWebsiteProducts: process.env.VUE_APP_HIDE_WEBSITE_PRODUCTS === 'true'
    };
  }
};
</script>
