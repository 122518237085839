import { login, logout, getInfo, companyMemberLogin } from '@/api/auth';
import { getToken, setToken, removeToken } from '@/utils/auth';

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  email: '',
  isCompanyMember: false
  // introduction: ''
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  // SET_INTRODUCTION: (state, introduction) => {
  //   state.introduction = introduction;
  // },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_EMAIL: (state, email) => {
    state.email = email;
  },
  SET_IS_COMPANY_MEMBER: (state, isCompanyMember) => {
    state.isCompanyMember = isCompanyMember;
  }
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then(data => {
          commit('SET_TOKEN', data.api_token);
          setToken(data.api_token);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  //企業登入
  companyMemberLogin({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      companyMemberLogin(userInfo)
        .then(data => {
          commit('SET_TOKEN', data.api_token);
          commit('SET_IS_COMPANY_MEMBER', true);
          setToken(data.api_token);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(data => {
          if (!data) {
            reject('Verification failed, please Login again.');
          }

          const { name, avatar, introduction, email, is_company_member } = data;

          commit('SET_NAME', name);
          commit('SET_AVATAR', avatar);
          commit('SET_EMAIL', email);
          // commit('SET_INTRODUCTION', introduction);
          commit('SET_IS_COMPANY_MEMBER', is_company_member);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('resetToken');
      resolve();
      // logout(state.token)
      //   .then(() => {
      //     commit('SET_TOKEN', '');
      //     removeToken();
      //     resolve();
      //   })
      //   .catch(error => {
      //     reject(error);
      //   });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
