//generate url by route resolve uri
export function generateUrlByRoute(routerResolve) {
  let domainUrl = window.location.origin;
  let groupUri = routerResolve.href;

  return `${domainUrl}/${groupUri}`;
}

export default {
  generateUrlByRoute
};
