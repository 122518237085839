import { isEmpty } from 'lodash';

const defaultAvatar = require('@/assets/img/default-avatar.png');

const getters = {
  token: state => state.user.token,
  avatar: state =>
    isEmpty(state.user.avatar) ? defaultAvatar : state.user.avatar,
  name: state => state.user.name,
  // introduction: state => state.user.introduction,
  userIsLogin: state => {
    return isEmpty(state.user.token) ? false : true;
  },
  apiLoading: state => state['loading-overlay'].isLoading,
  isCompanyMember: state => state.user.isCompanyMember === true
};
export default getters;
