import Cookies from 'js-cookie';
import router from '@/router';
import { isEmpty } from 'lodash';
import queryString from 'querystring';

const redirectToKey = 'redirect-to';

export function getRedirectTo() {
  return Cookies.get(redirectToKey);
}

export function setRedirectTo(redirectTo) {
  console.log(redirectTo);
  return Cookies.set(redirectToKey, redirectTo);
}

export function removeRedirectTo() {
  return Cookies.remove(redirectToKey);
}

export function redirectAfterAuth() {
  const redirectTo = getRedirectTo();
  removeRedirectTo();

  isEmpty(redirectTo)
    ? router.push({ name: 'Home' })
    : (window.location.href = window.location.origin + redirectTo);
  // : router.push(redirectTo);
}
