import request from '@/utils/request';

export function getVendors(
  { page, per_page, sort, dir, search },
  displayLoading = false
) {
  return request({
    url: 'vendors',
    method: 'get',
    params: {
      page,
      per_page,
      sort,
      dir,
      search
    },
    displayLoading
  });
}

export default {
  getVendors
};
