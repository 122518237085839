<template>
  <input
    class="timeonly"
    type="text"
    placeholder="請選擇開始時間"
    :value="time"
  />
</template>
<script>
import $ from 'jquery';
import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';

export default {
  props: {
    dataIndex: {
      required: false,
      type: [String, Number],
      default() {
        return null;
      }
    },
    dataKey: {
      required: false,
      type: String,
      default() {
        return null;
      }
    },
    time: {
      required: false,
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      options: {
        timePicker: true,
        singleDatePicker: true,
        timePicker24Hour: true,
        timePickerIncrement: 1,
        timePickerSeconds: false,
        locale: {
          format: 'HH:mm',
          applyLabel: '確認',
          cancelLabel: '取消'
        }
      }
    };
  },
  computed: {
    format() {
      return this.options.locale.format;
    }
  },
  mounted() {
    var vm = this;

    if (vm.time) {
      this.options.startDate = vm.time;
    }

    $(this.$el)
      .daterangepicker(this.options)
      //隱藏日曆
      .on('show.daterangepicker', function(ev, picker) {
        picker.container.find('.calendar-table').hide();
      })
      //選擇時間後
      .on('apply.daterangepicker', function(ev, picker) {
        let time = picker.startDate.format(vm.format);
        // console.log(picker);
        // console.log(picker.startDate.format('YYYY-MM-DD'));
        // console.log(picker.endDate.format('YYYY-MM-DD'));
        // console.log(time);
        let options = {
          key: vm.dataKey,
          index: vm.dataIndex
        };
        vm.$emit('update-time', time, options);
      })
      .on('cancel.daterangepicker', function(ev, picker) {
        //do something, like clearing an input
        if (vm.time) {
          $(vm.$el).val(vm.time);
        } else {
          $(vm.$el).val('');
        }
      })
      .val(vm.time)
      .attr('autocomplete', 'off')
      .attr('readonly', 'readonly');
  },
  beforeDestroy() {
    $(this.$el)
      .daterangepicker('hide')
      .daterangepicker('destroy');
  }
};
</script>
