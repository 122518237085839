import CategoryList from '@/views/search/category-list';
import VendorList from '@/views/search/vendor-list';
import ProductList from '@/views/search/product-list';
import ProductPage from '@/views/search/product';
import SearchHome from '@/views/search/index';

const searchProductRoute = [
  {
    name: 'SearchHome',
    path: '/vendor-categories',
    component: SearchHome,
    meta: {
      requiresAuth: true,
      pageTitle: '供應商',
      hidePrevBtn: true
    }
  },
  {
    name: 'VendorList',
    path: '/vendors',
    component: VendorList,
    meta: {
      requiresAuth: true,
      pageTitle: '供應商',
      hidePrevBtn: true
    }
  },
  {
    name: 'ProductCategoryList',
    path: '/providers/:vendor_id/categories',
    component: CategoryList,
    meta: {
      requiresAuth: true,
      pageTitle: '尋找商品',
      hidePrevBtn: true
    }
  },
  {
    name: 'ProductList',
    path: '/providers/:vendor_id/categories/:category_id/products',
    component: ProductList,
    meta: {
      requiresAuth: true,
      pageTitle: '尋找商品'
    }
  },
  {
    name: 'ProductPage',
    path: '/products/:product_id',
    component: ProductPage,
    meta: {
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true
    }
  }
];

export default searchProductRoute;
