const deliveryTypes = [
  {
    id: 'f2f',
    text: '面交',
    value: 1
  },
  {
    id: 'delivery',
    text: '寄送',
    value: 2
  }
];

export default deliveryTypes;
